Platform.Deferred.execute(setCartScript);


function setCartScript() {
    root = $('body').data('root');


    $(document).on('click', '.pf-checkout-cart-table .pf-cart-remove-btn', function (e) {
        e.preventDefault();
        var cartItemId = $(this).closest('tr').data('id');
        $.post(root + 'Cart/RemoveItem', { id: cartItemId }, function (data) {
            $(document).trigger('itemRemoved.cart.platform', data);
        });
        return false;
    });

    $(document).on('change', '.pf-checkout-cart-table .pf-qty-select', function () {
        var cartItemId = $(this).closest('tr').data('id');
        var qty = $(this).val();
        UpdateItemCartQty(cartItemId, qty);
    });

    $(document).on('change', '.pf-checkout-cart-table .pf-purchaserNote', function () {
        var cartItemId = $(this).closest('tr').data('id');
        var note = $(this).val();
        UpdateProductPurchaserNote(cartItemId, note);
    });
    $(document).on('change', '.pf-checkout-cart-table .pf-cart-notes-input', function () {
        var note = $(this).val();
        UpdateCartNote(note);
    });

    $(document).on('click', '.pf-btn-showAddNote', function () {
        var wrapper = $(this).closest('tr');
        wrapper.find('.pf-purchaserNote').show();
        $(this).hide();
    });

    $(document).on("click", "#discount-btn", function (e) {
        AddDiscountCode();
        return false;
    });
    var timer;
    function handleTimerQtyButtonsClick(cartItemId,qty)
    {
        timer = setTimeout(function () {
            UpdateItemCartQty(cartItemId, qty);
        }, 1000);
    }
    $(document).on('click', '.pf-checkout-cart-table .pf-qty-btn-minus', function (e) {
        console.log('cart updated qty btn pressed');
        e.preventDefault();
        clearInterval(timer);
        var cartItemId = $(this).closest('tr').data('id');
        var wrapper = $(this).closest('.pf-qty-wrapper');
        var qtyTbx = wrapper.find('.pf-qty-select');
        var uom = qtyTbx.data('uom');
        var qty = Number(qtyTbx.val());
        if (qty >= uom) {
            qty -= uom;
            qtyTbx.val(qty);
            handleTimerQtyButtonsClick(cartItemId, qty);
        }
    });

    $(document).on('click', '.pf-checkout-cart-table .pf-qty-btn-plus', function (e) {
        console.log('cart updated qty btn pressed');
        e.preventDefault();
        clearInterval(timer);
        var cartItemId = $(this).closest('tr').data('id');
        var wrapper = $(this).closest('.pf-qty-wrapper');
        var qtyTbx = wrapper.find('.pf-qty-select');
        var qty = Number(qtyTbx.val());
        var uom = qtyTbx.data('uom');
        qty += uom;
        qtyTbx.val(qty);
        handleTimerQtyButtonsClick(cartItemId, qty);
    });


    $('.pf-checkout-cart-table .pf-qty-select').keyup(function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            var cartItemId = $(this).closest('tr').data('id');
            var wrapper = $(this).closest('.pf-qty-wrapper');
            var qtyTbx = wrapper.find('.pf-qty-select');
            var qty = Number(qtyTbx.val());
            qty++;
            qtyTbx.val(qty);
            UpdateItemCartQty(cartItemId, qty);
        }
    });
}


function AddVoucherDone(data) {
    if (data.result == "ok") {
        $(document).trigger('discountUpdated.cart.platform', data);
    }
    else if (data.result == "fail") {
        // error
        alert("Error: " + data.err);
    }
    else if (data.msg == "order-total-not-reached") {
        $('#voucher-not-found').find('.pf-alert-msg').text("Discount only applies to orders over £" + parseFloat(data.amount).toFixed(2));
        $('#voucher-not-found').fadeIn();
    }
    else {
        $('#voucher-not-found').find('.pf-alert-msg').text("Invalid Discount Code");
        $('#voucher-not-found').fadeIn();
    }
}


function UpdateItemCartQty(cartId, qty) {
    console.log("update cart qty");
    $.post(root + "Cart/UpdateItemQuantity", {
        id: cartId,
        quantity: qty
    }, function (data) {
        $(document).trigger('itemUpdated.cart.platform', data);
    }, 'json');
}

function UpdateProductPurchaserNote(cartId, note) {
    console.log("update product note");
    $.post(root + "Cart/UpdateItemPurchaserNote", {
        id: cartId,
        note: note
    }, function (data) {
        $(document).trigger('itemUpdated.cart.platform', data);
    }, 'json');
}
function UpdateCartNote(note) {
    console.log("update cart note");
    $.post(root + "Cart/UpdateCartNote", {
        note: note
    }, function (data) {
        $(document).trigger('itemUpdated.cart.platform', data);
    }, 'json');
}

function AddDiscountCode() {
    var discountCode = $('#discount-input-tbx').val();
    if (discountCode !== '') {
        $.post(root + 'Cart/ApplyDiscountCode', { discountCode: discountCode }, AddVoucherDone, 'json');
    }
}

